import { on as onEvent } from "common/utils/ui/event";
import { isPhone, isMobileBrowser } from "common/utils/browser";
import { isFunction } from "common/utils/helpers/is";
import { api } from "jiffy-api";
import { loadPackage } from "common/utils/package-loader";
import { applyModalNavigation } from "common/jiffy/accessibility";

export default class CollapsableFiltersClass {
  constructor(
    props = {
      closeFilterSelector: "",
      filtersContainerSelector: "",
      openFiltersClassName: ""
    }
  ) {
    this.$close = document.querySelector(props.closeFilterSelector);
    this.$filtersContainer = document.querySelector(props.filtersContainerSelector);
    this.closeCallback = null;
    this.props = props;

    onEvent("click", props.closeFilterSelector, this.closeModal.bind(this), true);
  }

  onCloseCallback(callback) {
    this.closeCallback = callback;

    return this;
  }

  closeModal() {
    this.$filtersContainer.classList.remove(this.props.openFiltersClassName);
    isFunction(this.closeCallback) && this.closeCallback();

    return this;
  }

  openModal() {
    this.$close.focus();
    this.$filtersContainer.classList.add(this.props.openFiltersClassName);
    applyModalNavigation(this.$filtersContainer);

    return this;
  }

  async updatePriceSlider() {
    if (!this.$filtersContainer.querySelector(".js-price-slider")) return;

    const initPriceSlider = window.JiffyPriceSlider || (await loadPackage("price-slider"));
    initPriceSlider(this.$filtersContainer);
  }

  // eslint-disable-next-line class-methods-use-this
  getFiltersParams() {
    const url = new URL(window.location);
    const urlPath = url.pathname.split("/")[1];

    if (urlPath.includes(".html")) return {}; // If PDP return empty object

    return Array.from(url.searchParams).reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      {
        category_slug: urlPath
      }
    );
  }

  async getFilters() {
    const { html } = await api.jiffyshirts.facetsFilters(this.getFiltersParams());
    const $filter = document.createElement("div");
    $filter.innerHTML = html;
    this.$filtersContainer.appendChild($filter.firstChild);
    await this.updatePriceSlider();
  }

  loadFilters() {
    return new Promise(resolve => {
      const isMobile = () => isPhone() || isMobileBrowser();
      const getFilters = () => {
        if (!isMobile()) return;
        this.getFilters().then(resolve);
        window.removeEventListener("resize", getFilters, true);
      };

      getFilters();
      !isMobile() && window.addEventListener("resize", getFilters, true);
    });
  }
}
