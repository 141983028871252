import ToggleClass from "common/utils/ui/toggle-class";

export default class CollapsableFiltersToggleClass extends ToggleClass {
  constructor(
    props = {
      toggleSelector: ""
    }
  ) {
    super(props);

    this.$toggle = document.querySelector(props.toggleSelector);
  }

  setToggleFocus() {
    this.$toggle.focus();

    return this;
  }
}
