import { track } from "jiffy-analytics";
import {
  clickOnTitles,
  clickOnToggles,
  attachGAEvent
} from "components/jiffy/aside-filter/aside-filter-events";
import { initBadges } from "components/jiffy/j1-badge/j1-badge-popup/j1-badge-popup";
import initProductsPrimaryMaterialFilter from "components/jiffy/products-primary-material-filter/products-primary-material-filter";
import CollapsableFiltersToggleClass from "./collapsable-filters-toggle-class";
import CollapsableFiltersClass from "./collapsable-filters-class";
import "./collapsable-filters.scss";

const initCollapsableFiltersForm = () => {
  const $checkbox = document.querySelector(".js-collapsable-filters-checkbox");

  $checkbox?.addEventListener("change", event => {
    const $form = document.querySelector(".js-collapsable-filters-form");
    track("filter_only_in_stock_clicked", { toggle: event.target.checked, href: $form?.action });
    window.location.href = $form.action;
  });
};

const initCollapsableFiltersTrackingEvents = () => {
  clickOnTitles({
    titleClass: ".js-mobile-filters-title",
    linkClass: ".js-mobile-filters-link",
    collapsedTitleClassName: "collapsable-filters__label--collapsed",
    collapseTitleClassName: "collapsable-filters__label--collapse",
    hiddenLinkClassName: "collapsable-filters__value--hidden"
  });
  clickOnToggles({
    toggleClass: ".js-mobile-filters-toggle",
    linkClass: ".js-mobile-filters-link",
    collapseToggleClassName: "collapsable-filters__toggle--collapse",
    hiddenLinkClassName: "collapsable-filters__value--hidden"
  });
  document.querySelectorAll(".js-mobile-filters-ga-link").forEach(attachGAEvent);
};

const toggleTabIndexForProductSearch = tabIndex => {
  document.querySelector(".js-masthead-search__input")?.setAttribute("tabIndex", tabIndex);
  document.querySelector(".js-masthead-search__button")?.setAttribute("tabIndex", tabIndex);
};

export default () => {
  const collapsableFilters = new CollapsableFiltersClass({
    filtersContainerSelector: ".js-mobile-filters",
    closeFilterSelector: ".js-filters-back, .js-masthead-mobile-bar-toggler",
    openFiltersClassName: "collapsable-filters--opened"
  });

  const toggleFilters = new CollapsableFiltersToggleClass({
    toggleSelector: ".js-masthead-search-filter, .js-masthead-mobile-filters-toggler"
  });

  toggleFilters.onToggleCallback(() => {
    track("filter_modal_opened");
    toggleTabIndexForProductSearch(-1);
    collapsableFilters.openModal();
  });

  collapsableFilters.onCloseCallback(() => {
    track("filter_modal_closed");
    toggleTabIndexForProductSearch(0);
    toggleFilters.setToggleFocus();
  });

  collapsableFilters.loadFilters().then(() => {
    initCollapsableFiltersTrackingEvents();
    initBadges();
    initCollapsableFiltersForm();
    initProductsPrimaryMaterialFilter();
  });
};
